import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import BlockContent from "@sanity/block-content-to-react"
import Img from "gatsby-image"
import { getFluidGatsbyImage } from "gatsby-source-sanity"

import SEO from "../components/SEO"
import { PageSegment } from "../components"
import { GridOneColumn } from "../styles"
import { SubscribeNewsletter } from "../components/homePage"

const clientConfig = {
  sanity: {
    projectId: process.env.GATSBY_SANITY_PROJECT_ID!,
    dataset: process.env.GATSBY_SANITY_DATASET!,
  },
}

const imageSerializer = ({ node }: { node: any }) => {
  if (!node || !node.asset || !node.asset._ref) {
    return null
  }
  const fluidProps = getFluidGatsbyImage(
    node.asset._ref,
    { maxWidth: 675 },
    clientConfig.sanity
  )
  return (
    <figure>
      <Img fluid={fluidProps!} alt="blog-image" />
    </figure>
  )
}

const serializer = {
  types: { image: imageSerializer },
}

const PostContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  line-height: 1.6em;
  h1 {
    margin-top: 60px;
    margin-bottom: 30px;
    text-align: center;
  }
  @media (min-width: 768px) {
    font-size: 18px;
    max-width: 680px;
  }
`

export default function PostPage({
  data: { post },
  pageContext,
}: {
  data: any
  pageContext: any
}) {
  return (
    <>
      <SEO title={post.title} />
      <PageSegment background="var(--white)">
        <GridOneColumn>
          <PostContainer>
            <h1>{post.title}</h1>
            <BlockContent blocks={post._rawRichText} serializers={serializer} />
          </PostContainer>
        </GridOneColumn>
      </PageSegment>
      <SubscribeNewsletter />
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    post: sanityPost(slug: { current: { eq: $slug } }) {
      title
      _rawRichText
    }
  }
`
